'use client'
import Image from 'next/image'
import Link from 'next/link'
import logoBrand from '../img/brand.svg'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from './interfaces/appInterface'
import { toggleNav } from './app-store/navSlice'

export default function Home() {
  const isNavOpen = useSelector((state: RootState) => state.nav.isNavOpen)
  const dispatch = useDispatch()

  const showNav = () => {
    dispatch(toggleNav())
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-white">
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <Image
              src={logoBrand}
              className="navbar-brand-img"
              alt="kegow brand"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded={isNavOpen ? 'true' : 'false'}
            aria-label="Toggle navigation"
            onClick={showNav}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className={`collapse navbar-collapse ${
              isNavOpen ? 'show overflow-y-auto' : ''
            }`}
            id="navbarCollapse"
          >
            <button
              className="navbar-toggler text-primary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              onClick={showNav}
            >
              <span className="fs-2" aria-hidden="true">
                &times;
              </span>
            </button>

            <ul className="navbar-nav ms-auto">
              <li className="nav-item border-top-0">
                <a className="nav-link" href="/faq">
                  FAQ
                </a>
              </li>
            </ul>

            <Link
              className="navbar-btn btn btn-primary lift ms-auto"
              href="/signup"
            >
              Sign Up
            </Link>
          </div>
        </div>
      </nav>

      <section className="py-10 py-md-14 bg-primary" id="welcome">
        <div className="shape shape-blur-3 text-white">
          <svg
            viewBox="0 0 1738 487"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0h1420.92s713.43 457.505 0 485.868C707.502 514.231 0 0 0 0z"
              fill="url(#paint0_linear)"
            />
            <defs>
              <linearGradient
                id="paint0_linear"
                x1="0"
                y1="0"
                x2="1049.98"
                y2="912.68"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="currentColor" stopOpacity=".075" />
                <stop offset="1" stopColor="currentColor" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>{' '}
        </div>

        <div className="container">
          <div
            className="row justify-content-center"
            style={{ paddingBottom: '20px' }}
          >
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h1 className="display-2 fw-bold text-white">
                Payments Made Easy
              </h1>

              <p className="lead text-white mb-8">
                Forward-thinking businesses use Kegow to seamlessly disburse and
                collect payments
              </p>
              <Link
                href="/login/email"
                className="btn btn-white bg-white text-primary shadow lift"
              >
                Continue to Kegow Business
              </Link>
            </div>
          </div>
        </div>
      </section>
      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x bg-primary text-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z"
              fill="white"
            />
          </svg>{' '}
        </div>
      </div>

      <section className="pt-8 pt-md-10" id="about">
        <div className="container">
          {/* <div className="row">
            <div className="col-12 text-center">
              <a
                href="#about"
                className="btn btn-white btn-rounded-circle shadow mt-n12 mt-md-n14"
                data-scroll
              >
                <i className="bi bi-arrow-down-circle"></i>
              </a>
            </div>
          </div> */}
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <h2 className="fw-bold">
                Manage cash disbursements at{' '}
                <span className="text-primary">very low charges</span>.
              </h2>

              <p className="fs-lg text-body-secondary mb-9">
                Kegow business wallet caters for SMEs and NGOs and enables SMEs
                to easily manage treasury, invoicing, human resources, payroll,
                and payment schedule free of charge.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
